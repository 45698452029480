import React, { useEffect, useState } from "react";
import sanityClient from "../client.js";
import imageUrlBuilder from "@sanity/image-url";

import "./Partners.scss";

const pattern = /^image-([a-f\d]+)-(\d+x\d+)-(\w+)$/;

const decodeAssetId = (id) => {
  const [, assetId, dimensions, format] = pattern.exec(id);
  const [width, height] = dimensions.split("x").map((v) => parseInt(v, 10));

  return {
    assetId,
    dimensions: { width, height },
    format,
  };
};

const PartnerImage = (props) => {
  const { image, name } = props;
  const [portrait, setPortrait] = useState(false);

  const builder = imageUrlBuilder(sanityClient);
  function urlFor(source) {
    return builder.image(source);
  }

  useEffect(()=>{

    const dimensions = decodeAssetId(image.asset._ref);
    if(dimensions.dimensions.width <= dimensions.dimensions.height){
        setPortrait(true);
        console.log(true);
    }

  },[image.asset._ref])

  return (
    <img
      src={urlFor(image).url()}
      alt={name}
      className={`partners__logo ${portrait?'portrait':''}`}
    />
  );
};

export default PartnerImage;
