import React, { useEffect, useState } from "react";
import sanityClient from "../client.js";
import PartnerImage from "./PartnerImage.js";

import "./Partners.scss";

export default function Partners() {

  const [postData, setPostData] = useState(null);
  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type == "partners"] | order(order asc){
            name,
            partnerLogo,
            partnerLink
       }`
      )
      .then((data) => setPostData(data))
      .catch(console.error);
  }, []);

  return (
    <section className="partners__container">
      <div><h3>In partnership with:</h3></div>
      <div className="partners__wrapper">
        {postData &&
          postData.map((item, index) => (
            
            <div className="partners__partner-wrapper" key={index}>
                <PartnerImage image={item.partnerLogo} name={item.name}/>
            </div>
          ))}
      </div>
    </section>

  );
}
