import React, { useEffect, useState } from "react";
import sanityClient from "../client.js";
import VimeoEmbed from "./VimeoEmbed.js";
import "./Vimeo.scss";

const Vimeo = () => {
  const [postData, setPostData] = useState(null);

  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type == "vimeoEmbed"]{
            vimeoPassword,
            vimeoEmbed
            }`
      )
      .then((data) => setPostData(data))
      .catch(console.error);
  }, []);

  return (
    <section>
      {postData &&
        postData[0].vimeoEmbed.map((item, index) => {
          return (
            <VimeoEmbed
              title={item.entryTitle}
              vimeoId={item.entryId}
              password={postData[0].vimeoPassword}
              key={index}
            />
          );
        })}
    </section>
  );
};

export default Vimeo;
