// import React, { useRef } from "react";
import React from "react";
import Fade from "react-reveal/Fade";

import "./Vimeo.scss";

export default function VimeoEmbed(props) {
  // const inputWrapper = useRef();
  // const inputHint = useRef();
  // const input = useRef();

  // const handleSubmit = (e) => {
  //   e.preventDefault();
  //   if (input.current.value === props.password) {
  //     inputWrapper.current.classList.add("disabled");
  //   } else {
  //     inputHint.current.innerHTML = "Incorrect password";
  //     inputHint.current.classList.add("font-red");
  //   }
  // };

  return (
    <section className="vimeo__container">
      <Fade bottom>
        <h1 className="vimeo__title">{props.title}</h1>
      </Fade>
      <Fade bottom delay={400} distance={"150px"}>
        <div className="vimeo__wrapper">
          {/* <div className="vimeo__password-wrapper" ref={inputWrapper}>
            <form onSubmit={handleSubmit}>
              <label>
                <h3 ref={inputHint}>Enter password:</h3>
                <input type="password" name="password" ref={input} />
              </label>
              <input type="submit" value="GO" />
            </form>
          </div> */}
          <iframe
            title={"VimeoEmbed"}
            src={`https://player.vimeo.com/video/${props.vimeoId}`}
          ></iframe>
        </div>
      </Fade>
    </section>
  );
}