import React, { useState, useEffect } from "react";
import sanityClient from "../client.js";
import Fade from "react-reveal/Fade";

import "./Contact.scss";

const ContactLink = (props) => {
  return (
    <div>
      {props.type === "phoneNumber" ? (
        <h2>
          {props.title}: {props.link}
        </h2>
      ) : null}
      {props.type === "emailAddress" ? (
        <h2>
          <a href={`mailto:${props.link}`}>{props.title}</a>
        </h2>
      ) : null}
      {props.type === "website" ? (
        <h2>
          <a href={props.link} target="_blank" rel="noreferrer">
            {props.title}
          </a>
        </h2>
      ) : null}
    </div>
  );
};

export default function Contact() {
  const [postData, setPostData] = useState(null);
  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type == "siteInfo"]{
              contacts
              }`
      )
      .then((data) => setPostData(data))
      .catch(console.error);
  }, []);

  return (
    <section className="contact__container">
      <div className="contact__half left">
        <Fade bottom>
          <h1>get in touch:</h1>
        </Fade>
      </div>
      <div className="contact__half">
          {postData &&
            postData[0].contacts.map((item, index) => {
              return (
                <Fade bottom delay={400} distance={"150px"} key={index}>
                <ContactLink
                  type={item._type}
                  title={item.entryName}
                  link={item.entryLink}
                />
                </Fade>
              )
            })}
      </div>

      <div className="contact__copyright">
        All content &copy; Requiem In Motion {new Date().getFullYear()}
      </div>
    </section>
  );
}
