import React from "react";
import './styles/global-styles.scss';

import Preloader from "./components/Preloader";
// import Scene from "./components/Scene";
import About from "./components/About";
import Partners from "./components/Partners";
import Team from "./components/Team";
import Vimeo from "./components/Vimeo";
import Contact from "./components/Contact";


function App() {
  return (
    <div className="App">
      <Preloader />
      <About />
      <Partners />
      <Team />
      <Vimeo />
      <Contact />
    </div>
  );
}

export default App;
